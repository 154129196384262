<template>
  <div class="air_button" @click="test">
    <router-link class="air_button__content" to="/view-all/map">
      <span>View on map</span> <img src="../assets/img/plus-shape.svg" alt="" />
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    text: String,
    rot: String,
  },
  methods: {
    test() {
      this.$store.commit("openMap");
    },
  },
};
</script>

<style lang="scss" scoped>
.air_button {
  a {
    background: white;
    border-radius: 50px;
    padding: 14px 40px;
    font-family: "DM Sans";
    font-weight: 500;
    font-size: 20px;
    color: $light-blue;
    text-decoration: none;
    filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04))
      drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
    &:hover {
      background: $light-blue;
      color: white;
      filter: drop-shadow(0 20px 13px rgb(0 0 0 / 0.03))
        drop-shadow(0 8px 5px rgb(0 0 0 / 0.08));
      img {
        filter: brightness(100);
      }
    }
    img {
      margin-left: 24px;
    }
    @media screen and (max-width: 768px) {
      font-size: 16px;
    }
  }
  .air_button__content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
