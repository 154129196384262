import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    mapOpen: false,
    menuOpen: false,
    firstView: true,
    profile: "",
    loading: false,
  },
  getters: {},
  mutations: {
    openMap(state) {
      state.mapOpen = true;
    },
    closeMap(state) {
      state.mapOpen = false;
    },
    toggleMenu(state) {
      state.menuOpen = !state.menuOpen;
    },
    closeMenu(state) {
      state.menuOpen = false;
    },
    viewPage(state) {
      state.firstView = false;
    },
    pushProfile(state, prof) {
      state.profile = prof;
    },
    swLoad(state) {
      state.loading = true;
    },
  },
  actions: {},
  modules: {},
});
