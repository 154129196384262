<template>
  <div class="about">
    <div class="cardContainer__left cardContainer" v-if="loading">
      <div class="cardContRel">
        <div class="card__left card" id="cardOne">
          <img :src="imgList[0]" alt="" />
        </div>
      </div>
      <div class="card__left card" id="cardTwo">
        <img :src="imgList[1]" alt="" />
      </div>
    </div>
    <div class="cardContainer__right cardContainer">
      <div class="cardContRel">
        <div class="card__right card" id="cardThree">
          <img :src="imgList[2]" alt="" />
        </div>
        <div class="card__right card" id="cardFour">
          <img :src="imgList[3]" alt="" />
        </div>
      </div>
    </div>
    <div class="cardContainer__right"></div>
    <div class="about__firstBlock">
      <hero-title styl="about" />
      <div class="about__textContainer">
        <p class="about__text">
          It has happened to all of us to hear from, meet, work with women who
          will make us feel that we also can achieve great things. This person
          moves us, brings out the best in us and makes us feel that our work
          matters; in simple words, that person inspires us.
        </p>
        <p class="about__text">
          Would it be serendipity or something else, in Air Liquide, it happens
          we come across these women often. Through this project, we want to
          inspire you with their stories, their struggles, their joys, fears,
          their challenges and luck.
        </p>
        <p class="about__text">
          We hope these stories will push you to dare, to ask, try and strongly
          believe - as all we do - that women at Air Liquide are inspiring and
          we just have to celebrate it
        </p>
      </div>
      <div class="cta__container">
        <!-- <h3 class="cta__title">Want to propose a candidate?</h3> -->
        <div class="cta__flex">
          <!-- <air-link-ext
            class="cta-button"
            :text="'Submit a profile or your own story!'"
            :rot="'https://docs.google.com/forms/d/e/1FAIpQLSeCiiT4_q3O6nBKk-Gv1cLn_Vp58pEXpp1Q9_5BeXR8d6WPrg/viewform'"
          />
          <div class="button__divider">or</div> -->
          <air-link-ext
            class="cta-button"
            :text="'Join Air Liquide'"
            :rot="'https://www.airliquide.com/join-us/inclusion-diversity'"
            :invert="true"
          />
        </div>
      </div>
    </div>
    <div class="about__secondBlock">
      <h2 class="second__title">a brief history</h2>
      <p class="second__text">
        Everything started with <span class="text__bold">Nesrine</span>, GM for
        Benelux HCC activities. While reading a book to her 2 daughters,
        <span class="text__bold"
          >she got an idea: create a set of successful Air Liquide stories that
          could inspire all Air Liquide women.</span
        >
      </p>
      <p class="second__text">
        We created a team of 7 women, with different backgrounds, and
        nationalities but with the same ambition:
      </p>
      <p class="second__text text__itaBold">
        “Make Air Liquide an inspiring company for women, where they can develop
        themselves and contribute to key achievements for our societies and for
        the Group”
      </p>
      <p class="second__text">
        We launched a project: to create our Air Liquide Inspirational women
        book with
        <span class="text__bold">120 inspirational stories of women</span> with
        different profiles, different stories and from different Countries, for
        the 120th anniversary of Air Liquide.
      </p>
    </div>
    <div class="about__thirdBlock">
      <h3 class="third__title">The Team</h3>
      <p class="about__text"></p>
      <div class="team__container">
        <div class="team__el">
          <img src="../assets/img/nesnire.png" alt="" />
          <div class="team__name">Nesrine Djebali</div>
        </div>
        <div class="team__el">
          <img src="../assets/img/federica.png" alt="" />
          <div class="team__name">Federica Bellingeri</div>
        </div>
        <div class="team__el">
          <img src="../assets/img/jennifer.png" alt="" />
          <div class="team__name">Jennifer Leonardi</div>
        </div>
        <div class="team__el">
          <img src="../assets/img/muriel.png" alt="" />
          <div class="team__name">Muriel Jakazi</div>
        </div>
        <div class="team__el">
          <img src="../assets/img/maria.png" alt="" />
          <div class="team__name">Maria-Belen Lopez</div>
        </div>
        <div class="team__el">
          <img src="../assets/img/edith.png" alt="" />
          <div class="team__name">Edith Lemieux</div>
        </div>
        <div class="team__el">
          <img src="../assets/img/martine.png" alt="" />
          <div class="team__name">Martine Schneider</div>
        </div>
      </div>
      <h3 class="third__title partners">Our Partners</h3>
      <div class="group__container">
        <div class="partners__container">
          <p class="partner">
            <span class="role">Writer -</span> Catherine Jarvie
          </p>
          <p class="partner">
            <span class="role">Illustrator -</span> Anabel Llerena
          </p>
        </div>
        <div class="partners__container">
          <p class="partner">
            <span class="role">Digital art director -</span> Sofia Girelli
          </p>
          <p class="partner">
            <span class="role">Website developer -</span> Lorenzo Tempesti
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeroTitle from "@/components/HeroTitle.vue";
import AirLinkExt from "@/components/AirLinkExt.vue";

export default {
  components: { HeroTitle, AirLinkExt },
  data() {
    return {
      imgList: [],
      loading: false,
    };
  },
  watch: {
    "$store.state.loading": function () {
      this.mountImg();
    },
  },
  methods: {
    // getImgUrl(pic) {
    //   return require("../assets/img/compressed/small-" + pic + ".png");
    // },
    async mountImg() {
      this.dbWom = this.$store.state.profile;
      while (this.imgList.length < 4) {
        var index = Math.round(
          Math.random() * (this.$store.state.profile.length - 1)
        );
        var img = this.$store.state.profile[index].data.image.Small.url;
        if (!this.imgList.includes(img)) this.imgList.push(img);
      }
      this.loading = true;
    },
  },
  mounted() {
    if (this.$store.state.loading) this.mountImg();
  },
};
</script>

<style lang="scss" scoped>
.about {
  min-height: 100vh;
  width: 100%;

  // color: $primary-red;
}
.about__firstBlock {
  padding-bottom: 100px;
}
.about__textContainer {
  margin-top: 60px;
}

.about__text {
  font-family: Domaine Disp;
  color: $primary-blue;
  font-size: clamp(16px, 1.5vw, 28px);
  text-align: center;
  line-height: 160%;
  width: 40vw;
  margin: auto;
  margin-bottom: 42px;
  @media screen and (max-width: 769px) {
    font-size: 16px;
    width: 80vw;
  }
}

.cta__title {
  color: $primary-blue;
  font-family: Domaine Disp;
  font-weight: normal;
  text-align: center;
  padding: 16px 5vw;
  font-size: 2.5vw;
  @media screen and (max-width: 769px) {
    font-size: 22px;
    padding-top: 62px;
    padding-bottom: 32px;
  }
}

.cta-button {
  display: flex;
  justify-content: center;
  margin: auto;
}

.cta__flex {
  margin-top: 46px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 22px;
  @media screen and (max-width: 769px) {
    margin-top: 20px;
  }
  .button__divider {
    font-family: DM Sans;
    color: $primary-blue;
    font-size: 20px;
  }
}

.about__secondBlock {
  width: 100%;
  @include primary-bg-bright;
  padding: 82px 0;
  @media screen and (max-width: 769px) {
    padding: 32px 0;
  }
}

.second__title {
  color: white;
  font-family: Champion;
  text-align: center;
  font-weight: normal;
  text-transform: uppercase;
  font-size: 5.5vw;
  margin-bottom: 40px;
  @media screen and (max-width: 769px) {
    font-size: 80px;
    margin-bottom: 20px;
  }
}

.second__text {
  color: white;
  font-family: DM Sans;
  text-align: center;
  font-weight: normal;
  font-size: clamp(16px, 1.25vw, 20px);
  line-height: 167%;
  margin: auto;
  margin-bottom: 42px;
  width: 45vw;
  @media screen and (max-width: 769px) {
    width: 85vw;
  }
}

.text__bold {
  font-weight: bold;
}
.text__itaBold {
  font-weight: bold;
  font-style: italic;
}

.about__thirdBlock {
  width: 100%;
  padding: 40px 0;
}

.third__title {
  color: $primary-blue;
  font-family: Domaine Disp;
  font-weight: normal;
  text-align: center;
  margin: auto;
  font-size: clamp(28px, 2.5vw, 50px);
  margin-bottom: 40px;
}

.team__container {
  margin: auto;
  margin-top: 80px;
  width: 100%;
  max-width: 1400px;
  padding: 0 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 80px;
  @media screen and (max-width: 769px) {
    gap: 28px;
    padding: 0 5vw;
  }
}

.team__el {
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    @media screen and (max-width: 769px) {
      width: 144px;
      height: 144px;
    }
  }
  .team__name {
    margin-top: 20px;
    @include primary-color-bright;
    text-align: center;
    font-family: DM Sans;
    font-weight: 500;
    font-size: 20px;
    @media screen and (max-width: 769px) {
      font-size: 16px;
    }
  }
}

.cardContainer {
  top: 0;
  &__left {
    left: 0;
  }
  &__right {
    top: 0;
    right: 0;
  }
  position: absolute;
  @media screen and (max-width: 1040px) {
    display: none;
  }

  .card {
    position: absolute;
    width: 300px;
    height: auto;
    top: 0;
    left: 0;
    padding: 20px;
    background: #fdf4e4;

    img {
      width: 100%;
      height: auto;
    }
  }

  .cardContRel {
    position: relative;
    // border: 1px solid green;
    // width: 100px;
    // height: 100px;

    // background: red;
  }
}

#cardOne {
  transform: translate(-50px, 200px) rotate(-10deg);
}
#cardTwo {
  transform: translate(-20px, 510px) rotate(8deg);
}
#cardThree {
  transform: translate(-250px, 500px) rotate(12deg);
}
#cardFour {
  transform: translate(-220px, 810px) rotate(-8deg);
}

.partners {
  margin-top: 80px;
  margin-bottom: 32px;
}

.partners__container {
  font-family: DM Sans;
  @include primary-color-bright;
  flex-direction: column;
  // margin: auto;
  min-width: 260px;
  gap: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 20px;
  p > span {
    font-style: italic;
  }
  @media screen and (max-width: 769px) {
    font-size: 16px;
    gap: 12px;
    min-width: 40px;
    text-align: center;
    align-items: center;
  }
}

.group__container {
  display: flex;
  // max-width: 700px;
  margin: auto;
  gap: 40px;
  justify-content: center;
  @media screen and (max-width: 769px) {
    flex-direction: column;
    gap: 12px;
    align-items: center;
  }
  margin-bottom: 100px;
}
</style>
