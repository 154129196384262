<template>
  <div
    class="heroTitle__container"
    :class="[
      styl === 'map' ? 'mapStyle' : '',
      styl === 'menu' ? 'menuStyle' : '',
    ]"
  >
    <div class="title">
      <h1>
        <span id="insp">Inspiring</span> <br /><span id="wom">WOMEN</span>
      </h1>
    </div>
    <div class="subtible" v-if="styl === 'about'">
      <h2>
        120 INSPIRING STORIES FOR THE

        <br />
        120 YEARS OF AIR LIQUIDE
      </h2>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    styl: String,
  },
};
</script>

<style lang="scss" scoped>
.title {
  padding-top: 12vh;
  @media screen and (max-width: 769px) {
    padding-top: 24vh;
  }
}

h1 {
  @include primary-color-bright;
  text-align: center;
  font-size: 9.34vh;
  // font-size: 71.3713px;
  font-family: Love;
  font-weight: normal;
  letter-spacing: 0.015em;
  line-height: 255%;
  margin-bottom: 0;
  @media screen and (max-width: 769px) {
    font-size: 11.97vw;
  }

  #wom {
    font-family: Champion;
    letter-spacing: 0.013em;
    font-size: 37.95vh;
    // font-size: 290.622px;
    margin: 0;
    padding: 0;
    @media screen and (max-width: 769px) {
      font-size: 48.76vw;
    }
  }
}

h2 {
  margin: auto;
  // max-width: 430px;
  // max-width: 56.2vw;
  margin-top: -40px;
  text-transform: uppercase;
  font-family: "DM Sans";
  font-weight: normal;
  // font-size: 24px;
  font-size: 3.14vh;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.08em;
  color: $primary-blue;
  @media screen and (max-width: 769px) {
    margin-top: -20px;
    font-size: 4.2vw;
  }
}

.mapStyle {
  .title {
    padding-top: 0;
  }
  h1 {
    user-select: none;
    color: white;
    font-size: calc(9.34vh / 1.8);
  }
  #wom {
    font-size: calc(37.95vh / 1.8);
  }
  @media screen and (max-width: 769px) {
    h1 {
      font-size: calc(9.34vh / 3);
    }
    #wom {
      font-size: calc(37.95vh / 3);
    }
  }
}

.menuStyle {
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25vh;
    padding-top: 0;
  }
  h1 {
    user-select: none;
    font-size: calc(9.34vh / 2.3);
  }
  #wom {
    font-size: calc(37.95vh / 2.3);
  }
}
</style>
