<template>
    <div class="air_button">
        <div class="air_button__content" :class="tog ? '' : 'air_button__content--select'">
            All
        </div>
        <div class="air_button__content" :class="tog ? 'air_button__content--select' : ''">
            New
        </div>
    </div>
</template>

<script>
export default {
    props: {
        tog: Boolean
    },
    methods: {
        test() {
            this.$store.commit("openMap");
        },
    },
};
</script>

<style lang="scss" scoped>
.air_button {
    display: flex;
    background: white;
    border-radius: 50px;
    filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
    padding: 4px;
    // max-width: 300px;
    justify-content: center;


    .air_button__content {
        width: 50%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 36px;
        font-family: "DM Sans";
        font-weight: 500;
        font-size: 20px;
        color: $light-blue;
        text-decoration: none;

        &:hover {
            // background: $light-blue;
            @include primary-color-bright;
        }

        img {
            margin-left: 24px;
        }

        @media screen and (max-width: 768px) {
            font-size: 16px;
        }

        &--select{
            @include primary-bg-bright;
            color: white;
            border-radius: 50px;
            &:hover{
                color: white;
            }
            
        }
    }
}
</style>
