import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import VueSocialSharing from "vue-social-sharing";
import PrismicVue from "@prismicio/vue";
import linkResolver from "./link-resolver";

const accessToken = ""; // Leave empty if your repo is public
const endpoint = "https://inspiring-woman.prismic.io/api/v2"; // Use your repo name

Vue.config.productionTip = false;

Vue.use(VueSocialSharing);
Vue.use(VueAxios, axios);
Vue.use(PrismicVue, {
  endpoint,
  apiOptions: { accessToken },
  linkResolver,
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
