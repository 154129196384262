<template>
  <div class="viewAll">
    <transition name="slide-fade" v-on:after-leave="afterLeave">
      <div class="enterText__container" v-if="show && first">
        <div class="enterText__text">
          A celebration to those women, who have crossed your path or mine, and
          by their acts in everyday deeds, they have brought our minds to fly
          and our hearts to thrill
        </div>
      </div>
    </transition>
    <div v-if="guide && first" class="guideContainer">
      <img src="../assets/img/drag-2.svg" draggable="false" alt="" />
    </div>

    <div class="grid" :class="guide && first ? 'guide' : ''"></div>
    <div class="buttom__container buttom__container--filter">
      <air-toggle :tog="newFil" @click.native="filterNew" class="mapButton"/>
    </div>
    <div class="buttom__container">
      <air-button :text="'Discover all Woman'" :rot="'/view-all/map'" class="mapButton" />
    </div>
  </div>
</template>

<script>
import AirButton from "@/components/AirButton.vue";
import AirToggle from '../components/AirToggle.vue';
export default {
  components: { AirButton, AirToggle },
  data() {
    return {
      woImg: [
        "woman-1.png",
        "woman-2.png",
        "woman-3.png",
        "woman-4.png",
        "woman-5.png",
        "woman-6.png",
      ],
      show: true,
      clik: false,
      guide: true,
      dbWom: "",
      initialX: "",
      initialY: "",
      active: false,
      yOffset: 0,
      xOffset: 0,
      currentX: "",
      currentY: "",
      dragItem: "",
      grid: "",
      clickable: false,
      newFil: false,
    };
  },
  computed: {
    first() {
      return this.$store.state.firstView;
    },
  },
  watch: {
    "$store.state.loading": function () {
      this.mountGrid();
    },
  },
  methods: {
    filterNew(){
      this.newFil =! this.newFil
      this.mountGrid()
    },
    mountGrid() {

      if(!this.newFil) this.dbWom = this.$store.state.profile;
      else this.dbWom = this.$store.state.profile.filter(el => {
        return el.data.new
      });
      let winWid = window.innerWidth;
      let winHei = window.innerHeight;
      let margin = 40;

      this.grid = document.querySelector(".grid");
      while (this.grid.lastElementChild) {
        this.grid.removeChild(this.grid.lastElementChild);
      }
      let sfals = -1;
      var width = window.innerWidth > 768 ? 300 : 220;
      var height = window.innerWidth > 768 ? 440 : 300;

      let valGrid = Math.sqrt(this.dbWom.length);
      var row = Math.round(valGrid);
      var col = Math.ceil(valGrid);
      var dif = row * col - this.dbWom.length;
      var index = 0;
      let Nrow = 0;

      var aligX = winWid / 2 - (col * (width + margin)) / 2 + margin / 2;
      var aligY = winHei / 2 - (row * (height + margin)) / 2 - margin / 2;

      for (var t = 0; t < col; t++) {
        sfals *= -1;
        t - col + dif >= 0 ? (Nrow = row - 1) : (Nrow = row);
        for (var i = 0; i < Nrow; i++) {
          var el = document.createElement("div");
          var newTag = document.createElement("div");
          var link = document.createElement("a");
          link.href = window.location + "/" + this.dbWom[index].uid;
          // link.href = window.location + this.dbWom[index].uid;
          var img = document.createElement("img");
          var name = this.dbWom[index].data.women.split(" ");
          link.innerHTML = name[0];
          img.src = this.dbWom[index].data.image.Small.url;
          img.classList.add("grid__img");
          if (this.dbWom[index].data.new) el.classList.add("new");
          img.setAttribute("draggable", false);
          img.ondragstart = function () {
            return false;
          };
          el.style.transitionDuration = `${Math.random() * 300 + 400}ms`;
          el.classList.add("itemsTest");
          el.classList.add(index);
          el.style.top =
            aligY + i * height + i * margin + (height / 4) * sfals + "px";
          el.style.left = aligX + t * width + margin * t + "px";
          el.appendChild(img);
          el.appendChild(link);
          el.appendChild(newTag);
          this.grid.appendChild(el);
          index = index < this.dbWom.length - 1 ? index + 1 : 0;
        }
      }

      this.dragItem = document.querySelectorAll(".itemsTest");

      document.addEventListener("touchstart", this.dragStart, false);
      document.addEventListener("touchend", this.dragEnd, false);
      document.addEventListener("touchmove", this.drag, false);
      document.addEventListener("mousedown", this.dragStart, false);
      document.addEventListener("mouseup", this.dragEnd, false);
      document.addEventListener("mousemove", this.drag, false);

      this.show = false;
    },
    afterLeave() {
      this.clickable = true;
    },
    // getImgUrl(pic) {
    //   return require("../assets/img/compressed/small-" + pic + ".png");
    // },
    dragStart(e) {
      if (this.clickable || !this.first) {
        if (e.type === "touchstart") {
          this.initialX = e.touches[0].clientX - this.xOffset;
          this.initialY = e.touches[0].clientY - this.yOffset;
        } else {
          this.initialX = e.clientX - this.xOffset;
          this.initialY = e.clientY - this.yOffset;
        }
        this.active = true;
        this.guide = false;
      }
    },
    dragEnd() {
      this.initialX = this.currentX;
      this.initialY = this.currentY;
      this.grid.style.cursor = "grab";
      this.active = false;
    },
    drag(e) {
      if (this.active) {
        e.preventDefault();
        if (e.type === "touchmove") {
          this.currentX = e.touches[0].clientX - this.initialX;
          this.currentY = e.touches[0].clientY - this.initialY;
        } else {
          this.grid.style.cursor = "grabbing";
          this.currentX = e.clientX - this.initialX;
          this.currentY = e.clientY - this.initialY;
        }
        this.xOffset = this.currentX;
        this.yOffset = this.currentY;
        this.setTranslate(this.currentX, this.currentY, this.dragItem);
      }
    },
    setTranslate(xPos, yPos, el) {
      el.forEach((ele) => {
        ele.style.transform = "translate3d(" + xPos + "px, " + yPos + "px, 0)";
      });
    },
  },
  mounted() {
    if (this.$store.state.loading) this.mountGrid();
  },
  beforeDestroy() {
    document.removeEventListener("touchstart", this.dragStart, false);
    document.removeEventListener("touchend", this.dragEnd, false);
    document.removeEventListener("touchmove", this.drag, false);
    document.removeEventListener("mousedown", this.dragStart, false);
    document.removeEventListener("mouseup", this.dragEnd, false);
    document.removeEventListener("mousemove", this.drag, false);
    this.$store.commit("viewPage");
  },
};
</script>
<style lang="scss">
.viewAll {
  width: 100%;
  height: 100vh;
  position: relative;
}

.grid {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  cursor: grab;

  &__img {
    width: 280px;
    height: 420px;

    @media screen and (max-width: 769px) {
      width: 200px;
      height: 280px;
    }

    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    // border: 10px solid #fdf4e4;
  }

  &__items {
    position: absolute;
  }
}

.itemsTest {
  position: absolute;
  border: 10px solid #fdf4e4;
  width: 300px;
  transition: transform cubic-bezier(0, -0.01, 0.26, 1.02);
  filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));

  a {
    position: absolute;
    bottom: 20px;
    opacity: 0;
    margin: auto;
    text-align: center;
    max-width: 80%;
    left: 0;
    right: 0;
    font-family: DM Sans;
    font-size: 16px;
    @include primary-color-bright;
    background: #ffffffaa;
    padding: 8px 16px;
    text-decoration: none;
    border-radius: 20px;
    transition: opacity 300ms ease-out;

    &:hover {
      color: white;
      @include primary-bg-bright;
    }
  }

  &:hover {
    a {
      opacity: 1;
    }
  }

  @media (hover: none) {
    a {
      opacity: 1;
      background: white;
    }
  }

  @media screen and (max-width: 769px) {
    width: 220px;
  }
}

.mapButton {
  position: relative;
  left: -50%;

  @media screen and (max-width: 768px) {
    left: 0;
    padding: 0 20%;
  }
}

.buttom__container {
  position: absolute;
  left: 50%;
  bottom: 10vh;

  @media screen and (max-width: 768px) {
    width: 100%;
    left: 0;
  }

  &--filter {
    bottom: auto;
    top: 10vh;
      @media screen and (max-width: 768px) {
    padding: 0 20%;
  }
  }
}

.enterText__container {
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 99999;
  background: $bg-gradient;
  pointer-events: none;
}

.enterText__text {
  font-family: Domaine Disp;
  font-size: 2.5vw;
  color: $primary-blue;
  line-height: 144%;
  text-align: center;
  width: 50vw;

  @media screen and (max-width: 769px) {
    font-size: 26px;
    line-height: 156%;
    width: 85vw;
  }
}

.slide-fade-enter-active {
  transition: all 3s ease;
}

.slide-fade-leave-active {
  transition: all 5s ease-in;
  transition-delay: 1s;
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
}

.guideContainer {
  position: fixed;
  z-index: 998;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 180px;
    height: auto;
    -webkit-user-select: none;
    /* Chrome all / Safari all */
    -moz-user-select: none;
    /* Firefox all */
    -ms-user-select: none;
    /* IE 10+ */
    user-select: none;

    @media screen and (max-width: 769px) {
      width: 200px;
    }
  }
}

.guide div {
  filter: blur(5px);
  opacity: 0.6;
}

.new::after {
  content: '';
  top: 0;
  right: 0;
  position: absolute;
  background: url("https://inspiringwomen.airliquide.com/newStar.svg");
  background-size: 100%;
  width: 80px;
  height: 80px;

  @media screen and (max-width: 769px) {
    width: 60px;
    height: 60px;
    top: 5px;
    right: 5px;
  }
}
</style>
