<template>
  <div class="footer" v-if="show">
    <div class="copy">© Air Liquide 2022</div>
    <div class="policy">
      <a class="cky-banner-element">Cookie Settings</a> -
      <router-link to="/cookie-policy">Cookie Policy</router-link> -
      <a href="https://www.airliquide.com/legal-notice">Privacy Policy</a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //   bg: false,
      show: true,
    };
  },
  watch: {
    $route(to) {
      this.AddBg(to);
    },
  },
  methods: {
    AddBg(to) {
      //   if (to.name === "view-all") {
      //     this.bg = true;
      //   } else {
      //     this.bg = false;
      //   }
      if (to.name === "map" || to.name === "view-all") {
        this.show = false;
      } else {
        this.show = true;
      }
    },
  },
  mounted() {
    this.AddBg(this.$route);
  },
};
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  padding: 56px 56px 56px 56px;
  display: flex;
  justify-content: space-between;
  color: $primary-blue;
  font-family: DM Sans;
  font-size: 14px;
  @media screen and (max-width: 769px) {
    flex-direction: column;
    align-items: center;

    gap: 20px;
  }
  a {
    color: $primary-blue;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .policy {
    text-align: center;
  }
}
</style>
