<template>
  <div class="notfound">
    <div class="notfound__text">
      <h1>404</h1>
      <h2>OPS... page not found</h2>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.notfound {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notfound__text {
  text-align: center;
  font-family: DM Sans;
  h1 {
    @include primary-color-bright;
    font-size: 72px;
    font-weight: bold;
  }
  h2 {
    font-size: 28px;
    font-weight: normal;
    color: $primary-blue;
  }
}
</style>