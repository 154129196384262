<template>
  <div v-if="show" class="nav" :class="bg ? 'nav__bg' : ''">
    <div class="logo__container">
      <img src="@/assets/img/AIR_LIQUIDE.svg" alt="" />
    </div>
    <div class="nav-desk">
      <div class="nav__block" :class="red ? 'nav__red' : ''">
        <router-link to="/">Home</router-link>
        <router-link to="/view-all">View All</router-link>
      </div>
      <!-- <img src="@/assets/img/AIR_LIQUIDE.svg" alt="" /> -->
      <div class="nav__block left" :class="red ? 'nav__red' : ''">
        <router-link to="/about">About</router-link>
        <router-link to="/flipbook">Flipbook</router-link>
        <!-- <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSeCiiT4_q3O6nBKk-Gv1cLn_Vp58pEXpp1Q9_5BeXR8d6WPrg/viewform"
          target="blank"
          >Submit a profile</a
        > -->
      </div>
    </div>

    <div class="nav-mob">
      <div>
        <img
          @click="toogle"
          class="hamb"
          :class="red ? 'hamb__red' : ''"
          src="../assets/img/hambmenu.svg"
          alt=""
        />
      </div>
      <img class="logo" src="@/assets/img/AIR_LIQUIDE.svg" alt="" />
      <div class="filler"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bg: false,
      show: true,
      red: false,
      bgPage: ["view-all", "woman-story", "about-view"],
    };
  },
  watch: {
    $route(to) {
      this.AddBg(to);
    },
  },
  methods: {
    toogle() {
      this.$store.commit("toggleMenu");
    },
    AddBg(to) {
      // if (to.name === "view-all") {
      //   this.bg = true;
      // } else {
      //   this.bg = false;
      // }
      if (this.bgPage.includes(to.name)) {
        this.bg = true;
      } else {
        this.bg = false;
      }
      if (to.name === "download") {
        this.red = true;
      } else {
        this.red = false;
      }
      if (to.name === "map") {
        this.show = false;
      } else {
        this.show = true;
      }
    },
  },
  mounted() {
    this.AddBg(this.$route);
  },
};
</script>

<style lang="scss" scoped>
.nav {
  position: fixed;
  z-index: 9999;
  width: 100%;
  .nav-desk {
    @media screen and (max-width: 769px) {
      display: none;
    }
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 56px 16px 56px;
  }
  .nav-mob {
    display: none;
    width: 100vw;
    padding: 32px;
    @media screen and (max-width: 769px) {
      display: flex;
      justify-content: space-between;
    }
    .hamb {
      width: 30px;
      height: auto;
    }
    .filler {
      width: 30px;
    }
    .logo {
      width: 40vw;
    }
  }
}

img {
  //   width: 220px;
  width: 20vh;

  height: auto;
}

a {
  text-decoration: none;
  font-family: "DM Sans";
  font-weight: 500;
  color: $primary-blue;

  &:hover {
    text-decoration: underline;
  }
}

.nav__block a {
  margin-right: 44px;
}

// .left a:last-child {
//   margin-right: 0;
//   padding: 2px 8px;
//   @include primary-color-bright;
//   border: 1px solid $primary-red;
//   border-radius: 20px;
// }

a.router-link-exact-active {
  text-decoration: underline;
}

.nav__bg {
  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}

.nav__red a {
  @include primary-color-bright;
}
.hamb__red {
  filter: brightness(100);
}

.logo__container {
  position: absolute;
  display: flex;
  z-index: -20;
  // background: red;
  justify-content: center;
  top: 14px;
  width: 100%;
  @media screen and (max-width: 769px) {
    display: none;
  }
}
</style>
