<template>
  <div class="map-view">
    <router-link to="/view-all" class="close-button"
      ><img src="../assets/img/wclose.svg" alt=""
    /></router-link>
    <div class="filter">
      <div class="filter__label">FILTER BY</div>
      <div class="filter__dropdown">
        <select v-model="selected" class="filter__select">
          <option disabled value="">Geographies</option>
          <option value="Africa">Africa</option>
          <option value="Asia">Asia</option>
          <option value="Europe">Europe</option>
          <option value="North America">North America</option>
          <option value="Oceania">Oceania</option>
          <option value="South America">South America</option>
          <option value="">All</option>
        </select>
      </div>
    </div>
    <hero-title styl="map" class="mapTitle" />
    <MglMap
      :accessToken="accessToken"
      :mapStyle="mapStyle"
      :fitBound="array"
      @load="onMapLoaded"
    >
      <MglMarker
        v-for="(w, index) in filtered"
        :key="'popup:' + index"
        :coordinates="[
          w.data.geographies.longitude,
          w.data.geographies.latitude,
        ]"
        color="red"
      >
        <MglPopup
          :closeOnClick="true"
          :closeButton="false"
          anchor="top-left"
          @open="
            fly([w.data.geographies.longitude, w.data.geographies.latitude])
          "
        >
          <w-card
            :name="w.data.women"
            :sub="w.data.work_position"
            :code="w.uid"
            :url="w.data.image.Map.url"
          />
        </MglPopup>
      </MglMarker>
    </MglMap>
  </div>
</template>

<script>
import Mapbox from "mapbox-gl";
import { MglMap, MglMarker, MglPopup } from "vue-mapbox";
import WCard from "@/components/WCard.vue";
import HeroTitle from "@/components/HeroTitle.vue";
export default {
  components: {
    MglMap,
    MglMarker,
    MglPopup,
    WCard,
    HeroTitle,
  },
  data() {
    return {
      dbWom: "",
      selected: "",
      array: [],
      map: {},
      mapMeth: {},
      accessToken:
        "pk.eyJ1IjoidGVtcDA4IiwiYSI6ImNrMng4OWJyNjBmNDYzZ280ZnpsYWUyN3YifQ.rBtvUyD_V16SKpNTdhjElg", // your access token. Needed if you using Mapbox maps
      mapStyle: "mapbox://styles/temp08/cl01gx1te000p15paaynqo4m5", // your map style
    };
  },
  methods: {
    async bound(ar) {
      const asyncActions = this.map;
      var bounds = new this.mapMeth.LngLatBounds(ar[0], ar[0]);
      for (const coord of ar) {
        bounds.extend(coord);
      }
      const newParams = await asyncActions.fitBounds(bounds, {
        padding: 200,
        maxZoom: 5,
      });
    },
    async zoomout() {
      const asyncActions = this.map;
      const newParams = await asyncActions.flyTo({
        center: [30, 30],
        zoom: 1,
        speed: 1,
      });
    },
    async fly(coord) {
      const asyncActions = this.map;
      const newParams = await asyncActions.flyTo({
        center: [coord[0] + 5, coord[1]],
        zoom: 4,
        speed: 1,
      });
    },
    async onMapLoaded(event) {
      this.map = event.component.actions;
      this.mapMeth = event.component.mapbox;
    },
    async mountMap() {
      this.dbWom = this.$store.state.profile;
    },
  },
  mounted() {
    if (this.$store.state.loading) this.mountMap();
  },
  created() {
    this.mapbox = Mapbox;
    this.map = null;
  },
  computed: {
    filtered() {
      if (this.selected !== "") {
        var array = [];
        var fil = this.dbWom.filter(
          (el) => el.data.continents === this.selected
        );
        fil.forEach((el) => {
          array.push([
            el.data.geographies.longitude,
            el.data.geographies.latitude,
          ]);
        });
        if (array.length > 0) this.bound(array);
        else this.zoomout();
        return fil;
      } else return this.dbWom;
    },
  },
  watch: {
    "$store.state.loading": function () {
      this.mountMap();
    },
  },
};
</script>

<style lang="scss">
.map-view {
  width: 100%;
  height: 100vh;
  background: $primary-blue;
}
.mapboxgl-ctrl {
  display: none;
}
.filter {
  position: fixed;
  display: flex;
  align-items: center;
  top: 42px;
  left: 42px;
  z-index: 999;
  font-family: DM Sans;
  font-weight: normal;
  &__label {
    color: white;
    font-size: 20px;
    margin-right: 28px;
  }
  @media screen and (max-width: 769px) {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 22px;
    top: 56px;
    left: 28px;
    &__label {
      padding-left: 10px;
    }
  }
}
.filter__select {
  appearance: none;
  background-color: $primary-blue;
  color: $droplet;
  padding: 14px 1em 14px 40px;
  font-family: DM Sans;
  border: 1px solid $droplet;
  border-radius: 100px;
  font-size: 20px;
  min-width: 220px;
  @media screen and (max-width: 769px) {
    width: 70vw;
  }
}
.mapboxgl-popup-content {
  background: transparent;
  box-shadow: none;
  padding: 0;
  padding-top: 24px;
}
.mapboxgl-popup {
  max-width: none !important;
}
.mapboxgl-popup-tip {
  display: none;
}
.close-button {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  width: 46px;
  height: 46px;
  right: 46px;
  top: 42px;
  border-radius: 100%;
  background: $droplet;
  &:hover {
    background: white;
  }
  @media screen and (max-width: 769px) {
    right: 28px;
  }
}
.mapTitle {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 99;
}
</style>
