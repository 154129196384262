import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from 'vue-meta'
import HomeView from "../views/HomeView.vue";
import AllView from "../views/AllView.vue";
import MapView from "../views/MapView.vue";
// import MapViewAlt from "../views/MapViewAlt.vue";
import WomanStory from "../views/WomanStory.vue";
import About from "../views/AboutView.vue";
import Download from "../views/DowloadView.vue";
import Cookie from "../views/CookieView.vue";
import NotFound from "../views/NotFound.vue";

Vue.use(VueRouter);
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/view-all",
    name: "view-all",
    component: AllView,
  },
  {
    path: "/flipbook",
    name: "download",
    component: Download,
  },
  {
    path: "/view-all/map",
    name: "map",
    component: MapView,
  },
  // {
  //   path: "/view-all/map-test",
  //   name: "map-test",
  //   component: MapViewAlt,
  // },
  {
    path: "/view-all/:id",
    name: "woman-story",
    component: WomanStory,
  },
  {
    path: "/cookie-policy",
    name: "cookie",
    component: Cookie,
  },
  {
    path: "/about",
    name: "about-view",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () =>
    //   import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
    component: About,
  },
  {
    path: "*",
    name: "not-found",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
});

export default router;
