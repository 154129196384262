<template>
  <router-link :to="`/view-all/${code}`" class="wcard">
    <div class="wcard__img"><img :src="url" :alt="name" /></div>
    <div class="wcard__content">
      <div class="wcard__name">{{ name }}</div>
      <div class="wcard__subtitle">{{ sub }}</div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    name: String,
    sub: String,
    code: String,
    url: String,
  },
  methods: {
    // getImgUrl(pic) {
    //   return require("../assets/img/compressed/map-" + pic + ".png");
    // },
  },
};
</script>

<style lang="scss" scoped>
.wcard {
  display: flex;
  align-items: center;
  //   width: 300px;
  background: $moondance;
  border-radius: 0 0 0 50px;
  padding: 8px;
  cursor: pointer;
  flex-shrink: 0;
  text-decoration: none;

  &__img {
    width: 92px;
    height: 92px;
    aspect-ratio: 1;
    overflow: hidden;
    border-radius: 100%;
  }
  &__content {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    padding-right: 16px;
    max-width: 200px;
  }
}
.wcard__name {
  // color: $primary-red;
  @include primary-color-bright;
  font-family: Domaine Disp;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.01em;
  margin-bottom: 8px;
  text-decoration: none !important;
}
.wcard__subtitle {
  color: $primary-blue;
  font-family: DM Sans;
  font-size: 12px;
  line-height: 16px;
  text-decoration: none !important;
}
</style>
