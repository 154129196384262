<template>
  <div id="app">
    <div class="bg-element">
      <transition name="fade-cloud-l" appear>
        <img src="../src/assets/img/cloud.png" alt="" class="cloud cloud__left" />
      </transition>
      <transition name="fade-circle-r" appear>
        <img class="bgCircleImg bgCircleImg__right" src="../src/assets/img/circle-iw.svg" />
      </transition>
      <transition name="fade-circle-l" appear>
        <img class="bgCircleImg bgCircleImg__left" src="../src/assets/img/circle-iw.svg" />
      </transition>
      <transition name="fade-cloud-r" appear>
        <img src="../src/assets/img/cloud-r.png" alt="" class="cloud cloud__right" />
      </transition>
    </div>
    <nav-wom />
    <menu-comp v-if="open" />
    <router-view class="view" />
    <footer-comp class="foo" />
  </div>
</template>

<script>
import FooterComp from "./components/FooterComp.vue";
import MenuComp from "./components/MenuComp.vue";
import NavWom from "./components/NavWom.vue";
export default {
  components: { NavWom, FooterComp, MenuComp },
  data() {
    return {
      clol: "",
      clor: "",
      cirr: "",
      cirl: "",
    };
  },
  metaInfo() {
    return {
      title: 'Inspiring Women',
      meta: [
        { description: '120 Inspiring stories for the 120 years of Air Liquide' },
        { image: '/meta-inspiring-woman' },
        { property: 'og:title', content: 'Inspiring Women' },
        { property: 'og:description', content: '120 Inspiring stories for the 120 years of Air Liquide' },
        { property: 'og:site_name', content: 'Inspiring Women - Airliquide' },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: 'https://inspiringwomen.airliquide.com/' + this.$route.params.id },
        { property: 'og:image', content: '/meta-inspiring-woman' },
        { name: 'robots', content: 'index,follow' }
      ]
    }
  },
  watch: {
    $route() {
      this.$store.commit("closeMenu");
      if (this.$route.name === "view-all") {
        document.body.style.overflow = "hidden";
      } else document.body.style.overflow = "auto";
    },
  },
  computed: {
    open() {
      return this.$store.state.menuOpen;
    },
  },
  methods: {
    para(e) {
      this.clol.style.transform = `translate(${(e.clientX - window.innerWidth / 2) / 10
        }px, ${(e.clientY - window.innerHeight / 2) / 10}px)`;
      this.clor.style.transform = `translate(${(e.clientX - window.innerWidth / 2) / 12
        }px, ${(e.clientY - window.innerHeight / 2) / 12}px)`;
      this.cirr.style.transform = `translate(${(e.clientX - window.innerWidth / 2) / 28
        }px, ${(e.clientY - window.innerHeight / 2) / 118}px)`;
      this.cirl.style.transform = `translate(${(e.clientX - window.innerWidth / 2) / 22
        }px, ${(e.clientY - window.innerHeight / 2) / 28}px)`;
    },
    async getContent() {
      var profile = [];
        for(let i = 1; i <= 2; i++){
        const response = await this.$prismic.client.query(
          this.$prismic.Predicates.at("document.type", "woman_profile"),
          { orderings: "[my.woman_profile.uid]",
            pageSize: 100,
           page: i,
           }
        );

        response.results.forEach((el) => {
          profile.push(el);
        });
      }
      console.log(profile);
      this.$store.commit("pushProfile", profile);
      this.$store.commit("swLoad");
      },

    //   const response = await this.$prismic.client.query(
    //     this.$prismic.Predicates.at("document.type", "woman_profile"),
    //     {
    //       orderings: "[my.woman_profile.uid]",
    //       pageSize: 100,
    //       page: 2,
    //     }
    //   );
    //   var profile = [];
    //   response.results.forEach((el) => {
    //     profile.push(el);
    //   });
    //   this.$store.commit("pushProfile", profile);
    //   this.$store.commit("swLoad");
    // },
  },
  created() {
    // Call the API query method
    this.getContent();
  },
  mounted() {
    this.$store.commit("closeMenu");
    this.clol = document.querySelector(".cloud__left");
    this.clor = document.querySelector(".cloud__right");
    this.cirr = document.querySelector(".bgCircleImg__right");
    this.cirl = document.querySelector(".bgCircleImg__left");
    document.addEventListener("mousemove", (e) => this.para(e));
    if (this.$route.name === "view-all") {
      document.body.style.overflow = "hidden";
    } else document.body.style.overflow = "auto";
  },
  beforeDestroy() {
    document.removeEventListener("mousemove", (e) => this.para(e));
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "DM Sans";
  src: url("./assets/fonts/DMSans-Bold.woff2") format("woff2"),
    url("./assets/fonts/DMSans-Bold.woff") format("woff"),
    url("./assets/fonts/DMSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "DM Sans";
  src: url("./assets/fonts/DMSans-Medium.woff2") format("woff2"),
    url("./assets/fonts/DMSans-Medium.woff") format("woff"),
    url("./assets/fonts/DMSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "DM Sans";
  src: url("./assets/fonts/DMSans-Regular.woff2") format("woff2"),
    url("./assets/fonts/DMSans-Regular.woff") format("woff"),
    url("./assets/fonts/DMSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Love";
  src: url("./assets/fonts/Love-Regular.woff2") format("woff2"),
    url("./assets/fonts/Love-Regular.woff") format("woff"),
    url("./assets/fonts/Love-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Domaine Disp";
  src: url("./assets/fonts/DomaineDispMedium.woff2") format("woff2"),
    url("./assets/fonts/DomaineDispMedium.woff") format("woff"),
    url("./assets/fonts/DomaineDispMedium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Champion";
  src: url("./assets/fonts/Champion-HTF-Flyweight.woff2") format("woff2"),
    url("./assets/fonts/Champion-HTF-Flyweight.woff") format("woff"),
    url("./assets/fonts/Champion-HTF-Flyweight.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "DM Sans";
  src: url("./assets/fonts/DMSans-Italic.woff2") format("woff2"),
    url("./assets/fonts/DMSans-Italic.woff") format("woff"),
    url("./assets/fonts/DMSans-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: "DM Sans";
  src: url("./assets/fonts/DMSans-MediumItalic.woff2") format("woff2"),
    url("./assets/fonts/DMSans-MediumItalic.woff") format("woff"),
    url("./assets/fonts/DMSans-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: "DM Sans";
  src: url("./assets/fonts/DMSans-BoldItalic.woff2") format("woff2"),
    url("./assets/fonts/DMSans-BoldItalic.woff") format("woff"),
    url("./assets/fonts/DMSans-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: block;
}

#app {
  // min-height: 100vh;
  background: $bg-gradient;
  position: relative;

  @media screen and (max-width: 768px) {
    min-height: 100%;
    overflow: hidden;
  }
}

html {
  // min-height: 200px;
  // overflow: hidden;
  font-family: Arial, Helvetica, sans-serif;
}

.bg-element {
  position: fixed;
  z-index: 0;
  width: 100vw;
  height: 100vh;

  .bgCircleImg {
    transition: transform 5s cubic-bezier(0, -0.01, 0.26, 1.02);
  }

  .cloud {
    transition: transform 5s cubic-bezier(0, -0.01, 0.26, 1.02);
  }

  .cloud__left {
    position: relative;
    top: 11.7vh;
    left: -10vw;
    width: 52vw;
    height: auto;

    @media screen and (max-width: 769px) {
      width: 155vw;
      left: -40vw;
    }
  }

  .cloud__right {
    position: absolute;
    bottom: 10vh;
    right: -10vw;
    width: 43vw;
    height: auto;

    @media screen and (max-width: 769px) {
      width: 125vw;
      right: -40vw;
      bottom: 20vh;
    }
  }
}

.view {
  z-index: 9;
  position: relative;
}

.bgCircle {
  position: absolute;
  border-radius: 100%;

  &__right {
    width: 20vw;
    height: 20vw;
    border-width: 3px;
    border-style: solid;
    border-image: linear-gradient(to bottom, red, rgba(0, 0, 0, 0)) 1 100%;
  }
}

.bgCircleImg {
  position: absolute;

  &__right {
    width: 25.1vw;
    height: 25.1vw;
    bottom: -10vw;
    left: -5vw;
  }

  &__left {
    width: 25.1vw;
    height: 25.1vw;
    top: -5vw;
    right: 8vw;
  }
}

.foo {
  z-index: 99;
  position: relative;
}

.fade-cloud-l-enter-active,
.fade-cloud-r-enter-active,
.fade-circle-l-enter-active,
.fade-circle-r-enter-active {
  transition: all 2s cubic-bezier(0.22, 0.42, 0.44, 1.01);
}

.fade-leave-active {
  transition: all 5s ease-in;
  transition-delay: 1s;
}

.fade-cloud-l-enter {
  opacity: 0;
  transform: translate(-20vw, 0);
}

.fade-cloud-r-enter {
  opacity: 0;
  transform: translate(20vw, 0);
}

.fade-circle-l-enter {
  opacity: 0;
  transform: translate(2vw, -2vh);
}

.fade-circle-r-enter {
  opacity: 0;
  transform: translate(-2vw, 12vh);
}
</style>
