<template>
  <div class="menu">
    <div class="menuVoice__container">
      <router-link to="/">Home</router-link>
      <div class="divider"></div>
      <router-link to="/view-all">View All</router-link>
      <div class="divider"></div>
      <router-link to="/About">About</router-link>
      <div class="divider"></div>
      <router-link to="/flipbook">Flipbook</router-link>
      <div class="divider"></div>
      <!-- <a
        id="submit"
        href="https://docs.google.com/forms/d/e/1FAIpQLSeCiiT4_q3O6nBKk-Gv1cLn_Vp58pEXpp1Q9_5BeXR8d6WPrg/viewform"
        target="blank"
        >Submit a profile</a
      > -->
      <div class="divider"></div>
      <hero-title styl="menu" />
    </div>
    <div class="menuLogo"></div>
  </div>
</template>

<script>
import HeroTitle from "./HeroTitle.vue";
export default {
  components: { HeroTitle },
};
</script>

<style lang="scss" scoped>
.menu {
  width: 100%;
  position: fixed;
  height: 100vh;
  background: $bg-gradient;
  z-index: 999;
  top: 0;
  left: 0;
}
.menuVoice__container {
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  width: 100%;
  a {
    color: $primary-blue;
    font-family: Domaine Disp;
    font-size: 4vh;
    text-decoration: none;
    width: 100%;
    padding: 4.7vw 4.7vh;
    // border-bottom: 1px solid red;
  }
  .divider {
    width: 100%;
    height: 1px;
    background: linear-gradient(180deg, #e9afcb 0%, #cfbffa 100%);
  }
}
#submit {
  @include primary-color-bright;
}
</style>
