<template>
  <div class="story">
    <div class="story__container">
      <div  v-if="loading" class="story__content">
        <div class="newTag" v-if="dbContent.new">NEW</div>
        <h1>{{ dbContent.women }}</h1>
        <div class="content__text">
          <p class="text sels">{{ dbContent.sels }}</p>

          <p class="intro">{{ dbContent.text[0].text }}</p>

          <h3 class="quote">
            {{ dbContent.quote_1 }}
          </h3>
          <p
            v-for="(par, index) in dbContent.text.slice(1)"
            :key="'par:' + index"
            class="text"
          >
            {{ par.text }}
          </p>
          <h3 v-if="dbContent.quote_2" class="quote">
            {{ dbContent.quote_2 }}
          </h3>
        </div>
        <div class="bio__container">
          <h3 class="bio__name">{{ dbContent.women }}</h3>
          <h3 class="bio__name">{{ dbContent.work_position }}</h3>
          <p class="bio__text">{{ dbContent.bio }}</p>
        </div>
        <div class="share__container">
          Share
          <div class="shareButton__container">
            <ShareNetwork
              network="linkedin"
              :url="`https://inspiringwomen.airliquide.com/view-all/${this.$route.id}`"
              :title="dbContent.women"
              class="shareButton"
            >
              <img src="../assets/img/LinkedIn-logo.svg" alt="" />
            </ShareNetwork>
            <ShareNetwork
              network="twitter"
              :url="`https://inspiringwomen.airliquide.com/view-all/${this.$route.id}`"
              :title="dbContent.women"
              class="shareButton"
            >
              <img src="../assets/img/Twitter-logo.svg" alt="" />
            </ShareNetwork>
          </div>
        </div>
        <div class="cta__container">
          <!-- <h3 class="cta__title">Want to propose a candidate?</h3> -->
          <div class="cta__flex">
            <!-- <air-link-ext
              class="cta-button"
              :text="'Submit a profile or your own story!'"
              :rot="'https://docs.google.com/forms/d/e/1FAIpQLSeCiiT4_q3O6nBKk-Gv1cLn_Vp58pEXpp1Q9_5BeXR8d6WPrg/viewform'"
            />
            <div class="button__divider">or</div> -->
            <air-link-ext
              class="cta-button"
              :text="'Join Air Liquide'"
              :rot="'https://www.airliquide.com/join-us/inclusion-diversity'"
              :invert="true"
            />
          </div>
        </div>
      </div>
      <div class="story__imgContainer">
        <router-link
          v-if="backCode !== undefined"
          class="arrow__button mob"
          :to="'/view-all/' + backCode"
          ><img src="../assets/img/arr-left.svg" alt=""
        /></router-link>
        <div
         v-if="loading"
          class="story__imgStick"
          :style="`background-image: url(${dbContent.image.url})`"
        >
        <div class="newImg" v-if="dbContent.new"><img src="/newStar.svg" alt=""></div>
          <!-- <img src="/sample-wi.png" alt="" /> -->
          <div class="arrow__container desk">
            <router-link
              v-if="backCode !== undefined"
              class="arrow__button"
              :to="'/view-all/' + backCode"
              ><img src="../assets/img/arr-left.svg" alt=""
            /></router-link>
            <router-link
              v-if="nextCode !== undefined"
              class="arrow__button"
              :to="'/view-all/' + nextCode"
              ><img src="../assets/img/arr-right.svg" alt=""
            /></router-link>
          </div>
        </div>
        <router-link
          v-if="nextCode !== undefined"
          class="arrow__button mob"
          :to="'/view-all/' + nextCode"
          ><img src="../assets/img/arr-right.svg" alt=""
        /></router-link>
      </div>
    </div>
  </div>
</template>

<script>
import AirLinkExt from "@/components/AirLinkExt.vue";
export default {
  components: { AirLinkExt },
  data() {
    return {
      content: "",
      backCode: "",
      nextCode: "",
      paragraph: "",
      img: "",
      dbContent: "",
      loading: false,
    };
  },
      metaInfo() {
        return {
          title: this.dbContent.women,
          meta: [
                { property: 'og:title', content: this.dbContent.women + ' - Inspiring Women'},
                { property: 'og:site_name', content: 'Inspiring Women - Airliquide'},
                {property: 'og:type', content: 'website'},
                {property: 'og:url', content: 'https://inspiringwomen.airliquide.com/' + this.$route.params.id},
                {property: 'og:image', content: this.dbContent.image?.url }    
            ]
        }
        },
  watch: {
    "$route.params.id": function () {
      this.getContent(this.$route.params.id)
    },
},
  methods: {
    shareLink() {
      return window.location.href;
    },
    // getImgUrl(pic) {
    //   return require("../assets/img/compressed/big-" + pic + ".png");
    // },
    async getContent(k) {
      const response = await this.$prismic.client.getByUID("woman_profile", k);
      if (!response) this.$router.replace("/404");
      this.dbContent = response.data;
            this.loading = true;
      const prev = await this.$prismic.client.query(
        this.$prismic.Predicates.at("document.type", "woman_profile"),
        {
          pageSize: 1,
          after: `${response.id}`,
          orderings: "[my.woman_profile.uid desc]",
        }
      );
      const succ = await this.$prismic.client.query(
        this.$prismic.Predicates.at("document.type", "woman_profile"),
        {
          pageSize: 1,
          after: `${response.id}`,
          orderings: "[my.woman_profile.uid]",
        }
      );
      this.backCode = prev.results[0]
        ? prev.results[0].uid
        : this.$store.state.profile[this.$store.state.profile.length - 1].uid;
      this.nextCode = succ.results[0]
        ? succ.results[0].uid
        : this.$store.state.profile[0].uid;

    },
  },
  created() {
    this.getContent(this.$route.params.id);
    // this.getPrev()
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
.story {
  width: 100%;
}
.story__container {
  display: flex;
  width: 100%;
  min-height: 100vh;
  justify-content: space-between;
  @media screen and (max-width: 769px) {
    margin-top: 120px;
    flex-direction: column-reverse;
    align-content: center;
  }
}
.story__content {
  width: 100%;
  padding-top: 140px;
  padding-left: 3.89vw;
  padding-right: 3.89vw;
  padding-bottom: 10vh;
  @media screen and (max-width: 769px) {
    padding: 24px 32px 7vh 32px;
  }

  h1 {
    font-family: Champion;
    // color: $primary-red;
    @include primary-color-bright;
    font-size: 6.95vw;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    font-weight: normal;
    margin-bottom: 1.2vw;
    line-height: 100%;
    @media screen and (max-width: 769px) {
      font-size: 80px;
      line-height: 100%;
      text-align: center;
      margin-bottom: 10px;
    }
  }
  h2 {
    font-family: DM Sans;
    color: $primary-blue;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    font-weight: normal;
    font-size: clamp(14px, 1.4vw, 42px);
    @media screen and (max-width: 769px) {
      text-align: center;
      font-size: 16px;
    }
  }

  p {
    font-family: Domaine Disp;
    font-size: clamp(14px, 1.3vw, 42px);
    color: $primary-blue;
    line-height: 160%;
    letter-spacing: -0.01em;
    @media screen and (max-width: 769px) {
      font-size: 16px;
    }
  }
  .content__text {
    margin-top: 120px;
    @media screen and (max-width: 769px) {
      margin-top: 40px;
    }
  }

  .intro::first-letter {
    color: $primary-red;
    float: left;
    font-size: clamp(30px, 5.2vw, 150px);
    line-height: 4.1vw;
    padding-top: 0px;
    padding-right: 8px;
    padding-left: 3px;
    @media screen and (max-width: 769px) {
      font-size: 60px;
    }
  }

  .quote {
    font-family: DM Sans;
    font-weight: normal;
    font-style: italic;
    color: $primary-blue;
    font-size: clamp(20px, 1.8vw, 52px);
    line-height: 129%;

    letter-spacing: -0.01em;
    padding: 3vw;
    &::before {
      content: '"';
    }
    &::after {
      content: '"';
    }
    @media screen and (max-width: 769px) {
      padding: 30px 5vw;
    }
  }

  .text {
    margin-bottom: 42px;
    @media screen and (max-width: 769px) {
    }
  }

  .sels {
    font-family: DM Sans;
    @include primary-color-bright;
  }
}

.story__imgContainer {
  width: 100%;
  @media screen and (max-width: 769px) {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 12px;
  }
}

.story__imgStick {
  position: sticky;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: #fdf4e4;

  // background-image: url("sample-wi.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;

  @media screen and (max-width: 769px) {
    position: relative;
    margin: auto;
    width: 60vw;
    height: 40vh;
  }
}

.arrow__container {
  display: flex;
  position: absolute;

  gap: 12px;
  right: 46px;
  bottom: 46px;

  @media screen and (max-width: 769px) {
    background: red;
    width: 94vw;
    justify-content: space-between;
  }
}

.arrow__button {
  width: 46px;
  height: 46px;
  border-radius: 100%;
  border: 1px solid $droplet;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: $droplet;
  }

  img {
    margin: auto;
  }
}

.mob {
  @media screen and (min-width: 769px) {
    display: none;
  }
}
.desk {
  @media screen and (max-width: 769px) {
    display: none;
  }
}

.bio__container {
  margin-top: 80px;
  @media screen and (max-width: 769px) {
    margin-top: 60px;
  }

  .bio__name {
    // color: $primary-blue;
    @include primary-color-bright;
    font-weight: normal;
    font-size: clamp(20px, 1.5vw, 40px);
    font-family: Domaine Disp;
    @media screen and (max-width: 769px) {
      font-size: 20px;
    }
  }
  .bio__text {
    @include primary-color-bright;
    margin-top: 32px;
    font-family: DM Sans;
    font-size: clamp(16px, 1.3vw, 42px);
  }
}
.cta__container {
  margin-top: 80px;
}
.cta__title {
  color: $primary-blue;
  font-family: Domaine Disp;
  font-weight: normal;
  text-align: center;
  padding: 16px 5vw;
  font-size: 2.5vw;
  @media screen and (max-width: 769px) {
    font-size: 22px;
    padding-top: 62px;
    padding-bottom: 32px;
  }
}

.cta-button {
  display: flex;
  justify-content: center;
  margin: auto;
}

.cta__flex {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 22px;
  @media screen and (max-width: 769px) {
    margin-top: 20px;
  }
  div {
    // width: 100%;
  }
  .button__divider {
    font-family: DM Sans;
    color: $primary-blue;
    font-size: 20px;
    // margin-top: 32px;
    // margin-top: 32px;
  }
}

.share__container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 52px;
  font-family: DM Sans;
  color: $primary-blue;
  font-size: 16px;
  .shareButton__container {
    display: flex;
    gap: 20px;
    .shareButton {
      width: 36px;
      height: 36px;
      background: $light-blue;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      &:hover {
        @include primary-bg-bright;
      }
    }
  }
}

.newTag{
  margin-bottom: 8px;
  @include primary-bg-bright;
  border-radius: 30px;
  display: inline-block;
  font-size: 15px;
  padding: 1px 15px;
  color: white;
  font-family: Open Sans, Arial, Helvetica, sans-serif;
  font-weight: bold;  
  @media screen and (max-width: 769px) {
    width: 80px;
    margin:0 auto 22px auto;
    text-align: center;
    display: block;
  }
}

.newImg{
  position: absolute;
  bottom: 20px;
  left: 20px;
  @media screen and (max-width: 769px) {
    img{
    width: 70%;
    height: 70%;
    }
  }
}
</style>
