<template>
  <div class="download">
    <div class="down__container">
      <div
        style="
          position: relative;
          padding-top: max(60%, 326px);
          height: 0;
          width: 100%;
        "
      >
        <iframe
          sandbox="allow-top-navigation allow-top-navigation-by-user-activation allow-downloads allow-scripts allow-same-origin allow-popups allow-modals allow-popups-to-escape-sandbox"
          allowfullscreen="true"
          style="
            position: absolute;
            border: none;
            width: 100%;
            height: 80%;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
          "
          src="https://e.issuu.com/embed.html?backgroundColor=%230D3695&backgroundColorFullscreen=%230D3695&d=airliquide_booklet&hideIssuuLogo=true&hideShareButton=true&u=inspiring_women"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.download {
  width: 100%;
  min-height: 100vh;
  background: $primary-blue;
  padding-top: 80px;
}
.down__container {
  min-height: 100vh;
  // padding-top: 100px ;
  display: flex;
  align-items: center;
}
</style>
